import bigLogo from '../assets/images/brands/logo_at_bottom.svg';
import moveToTopIcon from '../assets/images/arrow_up.svg';

import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap';

function BottomNav() {

    const handleImageClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const cssForNavLink = 'nav-link text-color-black px-0 py-2 py-md-3 glowva-text-h6';
    // const cssForEmailLink = 'text-color-black text-nowrap';
    // const maryEmail = 'mary@happyparentstribe.com';

    return (
        <>
            <div className="row pt-4">
                <div className="col-4 col-md-4 d-flex justify-content-center align-items-center text-center mx-auto">
                    <img src={bigLogo} alt="logo" className="mx-auto" style={{ width: "70%", maxWidth: "128px" }} />
                </div>

                <div className="d-none d-md-block col-md-1"></div>
                {/* navigation */}
                <div className="col-7 col-md-6">
                    <div className="row">
                        <div className="d-lg-none col-1">&nbsp;</div>
                        <div className="col-7">
                            <Navbar>
                                <Container fluid>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav>

                                            <div className="d-flex flex-lg-row flex-column">
                                                <div className={`d-flex flex-column me-lg-3`}>
                                                    <NavLink to="/Resources" className={`${cssForNavLink}`}>Resources</NavLink>
                                                    <NavLink to="/Community" className={`${cssForNavLink}`}>Community</NavLink>
                                                </div>

                                                <div className={`d-flex flex-column ms-lg-3`}>
                                                    {/* <div className={`${cssForNavLink}`}>
                                                        <a href={`mailto:${maryEmail}`} className={`text-decoration-none ${cssForEmailLink}`}>Email: {maryEmail}</a>
                                                    </div> */}
                                                    <NavLink to="/FAQ" className={`${cssForNavLink}`}>FAQ</NavLink>
                                                    <NavLink to="/Contact" className={`${cssForNavLink}`}>Contact</NavLink>
                                                </div>
                                            </div>

                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </div>

                        <div className="col-4">&nbsp;</div>
                    </div>
                </div>
                {/* end navigation */}

                <div className="col-1 py-2 py-md-3">
                    <div>
                        <img src={moveToTopIcon} onClick={handleImageClick} alt="move to top"
                            className="move-to-up-icon" style={{ position: "absolute", right: "5%" }} />
                    </div>

                </div>

            </div>

        </>
    );
}

export default BottomNav;

/*

function BottomNav() {

    const handleImageClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const cssForNavLink = 'nav-link text-color-black px-0 py-2 py-md-3 glowva-text-h6';
    // const cssForEmailLink = 'text-color-black text-nowrap';
    // const maryEmail = 'mary@happyparentstribe.com';

    return (
        <>
            <div className="row pt-4">
                <div className="col-4 col-md-4 d-flex justify-content-center align-items-center mx-auto">
                    <img src={bigLogo} alt="logo" className="img-fluid mx-auto" style={{ width: "168px"}}/>
                </div>

                <div className="d-none d-lg-block col-1"></div>
                {/* navigation }
                <div className="col-7 col-md-6">
                    <div className="row">
                        <div className="d-lg-none col-1">&nbsp;</div>
                        <div className="col-7">
                            <Navbar>
                                <Container fluid>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav>

                                            <div className="d-flex flex-lg-row flex-column">
                                                <div className={`d-flex flex-column me-lg-3`}>
                                                    <NavLink to="/Resources" className={`${cssForNavLink}`}>Resources</NavLink>
                                                    <NavLink to="/Community" className={`${cssForNavLink}`}>Community</NavLink>
                                                </div>

                                                <div className={`d-flex flex-column ms-lg-3`}>
                                                    {/* <div className={`${cssForNavLink}`}>
                                                        <a href={`mailto:${maryEmail}`} className={`text-decoration-none ${cssForEmailLink}`}>Email: {maryEmail}</a>
                                                    </div> }
                                                    <NavLink to="/FAQ" className={`${cssForNavLink}`}>FAQ</NavLink>
                                                    <NavLink to="/Contact" className={`${cssForNavLink}`}>Contact</NavLink>
                                                </div>
                                            </div>

                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </div>

                        <div className="col-4">&nbsp;</div>
                    </div>
                </div>
                {/* end navigation }

                <div className="col-1 py-2 py-md-3">
                    <div>
                        <img src={moveToTopIcon} onClick={handleImageClick} className="move-to-up-icon" alt="move to top" />
                    </div>

                </div>

            </div>

        </>
    );
}

*/