import './App.css';

import TopNav from './components/TopNav';
import BottomNav from './components/BottomNav.jsx';
import { lazy, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import Copyright from './components/Copyright.jsx';
import AppleAppSiteAssociation from './components/AppleAppSiteAssociation.jsx'

// lazy load
const Home = lazy(() => import('./containers/Home.jsx'));
const Resources = lazy(() => import('./containers/Resources.jsx'));
const Community = lazy(() => import('./containers/Community.jsx'));
const Contact = lazy(() => import('./containers/Contact.jsx'));
const FAQ = lazy(() => import('./containers/FAQ.jsx'));
const Error404 = lazy(() => import('./containers/Error404.jsx'));

function App() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname === '/.well-known/app-app-site-association') {
    return <AppleAppSiteAssociation />;
  }

  return (
    <>
      {/* className="container-fluid" */}
      <div className="container-fluid px-0 mx-0">
        <div className="row">
          <div className="col-12">
            <TopNav></TopNav>
          </div>
        </div>

        {/* navigation router */}
        <div className="row">
          {/* <div className="col-12"> */}
          {/* Lazy load */}
          <Suspense fallback={<div>Loading...</div>}>
            {/* Register routes */}
            <Routes>
              <Route path="/Home" element={<Home />} />
              <Route path="/Resources" element={<Resources />} />
              <Route path="/Community" element={<Community />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/FAQ" element={<FAQ />} />
              {/* Redirect */}
              <Route path="/" element={<Navigate to="/Home" />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
          {/* </div> */}
        </div>
        {/* end navigation router */}

        <BottomNav></BottomNav>
        <Copyright></Copyright>
      </div>
    </>
  );
}

export default App;
