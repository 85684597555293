import React from 'react';

const AppleAppSiteAssociation = () => {
    const data = {
        "applinks": {
            "apps": [],
            "details": [
                {
                    "appId": "3GQKBPS9PZ.com.glowva.app.glowva",
                    "paths": [
                        "NOT /_/",
                        "/"
                    ]
                }
            ]
        }
    };

    return (
        <pre>
            {JSON.stringify(data, null, 2)}
        </pre>
    );
};

export default AppleAppSiteAssociation;