import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap';

import Logo from '../assets/images/brands/logo.svg';

function TopNav() {
    const navigate = useNavigate();
    const location = useLocation();

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const [scrollToSubscription, setScrollToSubscription] = useState(false);

    const goToSubscription = () => {
        // Get the subscription field ref from the Home component
        const subscriptionFieldRef = document.getElementById("subscription-field-section");
        if (subscriptionFieldRef) {
            subscriptionFieldRef.scrollIntoView({ behavior: "smooth" });
            setScrollToSubscription(false);
        }
    }

    const delayGoToSubscription = () => {
        if (window.innerWidth < 991) { // mobile devices
            setExpanded(false); // collapse the nav bar
            setTimeout(() => {
                goToSubscription();
            }, 200); // delay the scroll to allow the nav bar to collapse
        } else {
            goToSubscription();
        }
    }

    const handleJoinUsClick = (event) => {
        event.stopPropagation();
        setScrollToSubscription(true);
        setExpanded(false);

        // Check if we're not already on the /Home page
        if (location.pathname !== "/Home") {
            navigate("/Home");
            // then useEffect will be triggered
        } else {
            delayGoToSubscription();
        }
    }

    useEffect(() => {
        if (location.pathname === '/Home' && scrollToSubscription) {
            delayGoToSubscription();
        }
    },);

    const handleLogoClick = () => {
        navigate("/Home");
    }

    return (
        <>
            <div className="row glowva-text-h5 pe-0 d-flex">
                <div className="col text-center d-block d-lg-none" style={{ position: "absolute", top: "1.5%" }}>
                    <img src={Logo} alt="glowva logo" className="nav-bar-logo ps-2" onClick={handleLogoClick} />
                </div>

                <Navbar expand="lg" expanded={expanded} onToggle={handleToggle} collapseOnSelect className="navbar-container">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">

                            <Nav className="ms-4 me-0 px-0">
                                <div className="d-flex align-items-center col-2">
                                    <NavLink to="/Home" className="nav-link">
                                        <img src={Logo} alt="glowva logo" className="d-none d-lg-block nav-bar-logo ps-2" />
                                    </NavLink>
                                </div>

                                <div className="col-4"></div>
                                {/* space between logo and nav bar */}

                                <div className="d-flex align-items-center mx-3">
                                    <NavLink to="/Home" className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Home</NavLink>
                                </div>
                                <div className="d-flex align-items-center mx-3">
                                    <NavLink to="/Resources" className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Resources</NavLink>
                                </div>
                                <div className="d-flex align-items-center mx-3">
                                    <NavLink to="/Community" className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Community</NavLink>
                                </div>
                                <div className="d-flex align-items-center mx-3">
                                    <NavLink to="/Contact" className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Contact</NavLink>
                                </div>
                                <div className="d-flex align-items-center mx-3">
                                    <NavLink to="/FAQ" className="nav-link nav-top-item" onClick={() => setExpanded(false)}>FAQ</NavLink>
                                </div>
                                <div className="d-flex align-items-center ms-3 text-end pe-0">
                                    <button type="button" className={`text-nowrap btn-join-us`} onClick={handleJoinUsClick}>
                                        <span className="nav-top-item btn-text">Join us</span>
                                    </button>
                                </div>
                            </Nav>


                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            </div>
        </>
    );
}

export default TopNav;